import React from 'react';

import {Link} from 'gatsby';
import classNames from "classnames";
import {SVGSupportImg} from "../SVGSupportImg";
import {Author} from "../Author";

const styles = require('./BookMediaObject.module.scss');

export function BookMediaObject({title, slug, coverImage, authors, className, ...otherProps}) {
    return <Link to={`/insights/${slug}`} className={classNames(styles.root, className)} {...otherProps}>
        <SVGSupportImg {...coverImage} className={styles.image}/>
        <h1 className={styles.title}>{title}</h1>

        <div className={styles.authors}>
            {authors.map((x, i) => {
                return <Author name={x.name} avatar={x.avatar.localFile.childImageSharp} key={i}/>;
            })}
        </div>
    </Link>
}
