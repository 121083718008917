import {Layout} from "../components/Layout";
import React from 'react';
import {PageMainColumnWrapper} from "../components/PageMainColumnWrapper";
import {graphql} from "gatsby";
import {SectionHero} from "../components/SectionHero";
import {Sections} from "../components/Sections";
import {BackgroundLine} from "../components/BackgroundLine";
import {BooksList} from "../components/BooksList";
import {createSeoData} from "../functions/createSeoData";

const styles = require('./insights.module.scss');

export const query = graphql`query Insights($locale: String!, $slug: String!) {
  page: contentfulSpecialPage(slug: {eq: $slug}) {
    title
    hero {
      ...SectionHero
    }
    sections {
      ...AllSectionsContent
    }
    seo {
      ...Seo
    }
  }
  books: allContentfulBook(filter: {node_locale: {eq: $locale}}) {
    nodes {
      slug
      title
      coverImage {
        localFile {
          url
          childImageSharp {
            gatsbyImageData(width: 270, height: 400, placeholder: BLURRED, layout: FIXED)
          }
        }
      }
      authors {
        name
        avatar {
          id
          localFile {
            childImageSharp {
              gatsbyImageData(width: 32, height: 32, layout: FIXED)
            }
          }
        }
      }
    }
  }
}
`

export default function InsightsPage({data, pageContext}) {
    const hero = data.page.hero;
    return <Layout services={pageContext.services}
                   oldServices={pageContext.oldServices}
                   linkableSubServices={pageContext.linkableSubServices}
                   title={data.page.title}
                   jobsCount={pageContext.jobsCount}
                   companyData={pageContext.companyData}
                   seo={createSeoData(data.page.seo)}
                   background={<>
                       <BackgroundLine mobileOn={false}/>
                   </>}
                   lang={pageContext.lang}>
        <PageMainColumnWrapper>
            <SectionHero
                title={hero.title}
                content={hero.content}
                image={hero.image?.localFile}/>
            <div className={styles.books}>
                <BooksList books={data.books.nodes.map(x => {
                    return {...x, coverImage: x.coverImage.localFile}
                })}/>
            </div>
            <Sections
                sections={data.page.sections}
            />
        </PageMainColumnWrapper>
    </Layout>
}
